'use client';

import setup from 'lib/http/setup';
import { DeviceProvider } from '@mentimeter/ragnar-device';
import { ThemeProvider } from '@mentimeter/ragnar-react';
import { useEffect } from 'react';
import { I18nextProvider } from 'react-i18next';
import i18next from 'app/i18n/client';
import { stripAllNonNumericChars } from '@mentimeter/ragnar-utils';
import { designSystemConfig } from '@mentimeter/ragnar-dsc';
import { LocalStorage } from '@mentimeter/storage';
import { FelaProvider } from '../FelaProvider';
import { SplitIOSetup } from '../../split-setup';
import { AppStateProvider } from './AppStateProvider';

setup();

export function Providers({
  lang,
  newlyGeneratedServerToken,
  country,
  children,
}: {
  lang: string;
  newlyGeneratedServerToken: string;
  country: string | null;
  children: React.ReactNode;
}) {
  const breakpoints = designSystemConfig.breakpoints.map((b) =>
    stripAllNonNumericChars(b),
  );

  useEffect(() => {
    i18next.changeLanguage(lang);
  }, [lang]);

  // TODO: This is to replicate a request to /split-data
  // and setting up the proper data in LocalStorage (country, prefferedLanguage).
  // This functionality will be needed in other apps too once they are more server-sided.
  // Think about a good way to place this to be shared (might be a part of SplitIO package).
  if (LocalStorage.getItem('data-split-country') === null) {
    LocalStorage.setItem({
      key: 'data-split-country',
      value: country ?? 'none',
      type: 'functional',
    });
  }

  if (LocalStorage.getItem('data-split-preferredLanguage') === null) {
    LocalStorage.setItem({
      key: 'data-split-preferredLanguage',
      value: lang,
      type: 'functional',
    });
  }

  return (
    <DeviceProvider breakpoints={breakpoints}>
      <ThemeProvider theme={designSystemConfig} themeClass="light">
        <FelaProvider>
          <AppStateProvider
            newlyGeneratedServerToken={newlyGeneratedServerToken}
          >
            <SplitIOSetup>
              {/* <DevelopmentToolsSetup
            experiments={{
              ...visitorExperiments,
              ...userExperiments,
              }}
              isAuthorizedToInteractWithDevelopmentTools={isAuthorizedToInteractWithDevelopmentTools()}
              > */}
              <I18nextProvider i18n={i18next}>{children}</I18nextProvider>
              {/* </DevelopmentToolsSetup> */}
            </SplitIOSetup>
          </AppStateProvider>
        </FelaProvider>
      </ThemeProvider>
    </DeviceProvider>
  );
}

// When we removed the use of locale in LocalStorage this is not needed.
// function useMigrateLocalStorageLocaleToCookie() {
//   useEffect(() => {
//     const localStorageLocale = LocalStorage.getItem(USER_LOCALE_STORAGE_KEY);
//     const cookieLocale = Cookies.get(USER_LOCALE_STORAGE_KEY);
//     if (localStorageLocale && !cookieLocale) {
//       Cookies.set({
//         type: 'functional',
//         name: USER_LOCALE_STORAGE_KEY,
//         value: localStorageLocale,
//         options: {
//           expires: 365,
//           secure: true,
//           sameSite: 'lax',
//         },
//       });
//       window.location.reload();
//     }
//   }, []);
// }
