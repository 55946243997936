/* eslint-disable menti-react/filename-convention--jsx */
import type { ReactNode } from 'react';
import { useMemo } from 'react';
import { SplitFactory } from '@splitsoftware/splitio-react';
import { SplitOverridesProvider } from '@mentimeter/splitio';
import { useSearchParams } from '@mentimeter/next-navigation';
import { isAuthorizedToInteractWithDevelopmentTools } from '@mentimeter/user';
import { visitorExperiments, userExperiments } from './split-experiments';
import { useAppState } from './appState';
import { SplitIOUserProvider, SplitIOVisitorProvider } from './split-hooks';

const SPLIT_BROWSER_API_KEY = process.env.NEXT_PUBLIC_SPLIT_BROWSER_API_KEY;

const experiments = {
  ...visitorExperiments,
  ...userExperiments,
};

/**
 * This is not a foolproof JWT decoding fn, but it is good enough for the simple UUIDs we use.
 *
 * https://stackoverflow.com/questions/38552003/how-to-decode-jwt-token-in-javascript-without-using-a-library
 * @param token A JWT string
 * @returns The decoded JWT object
 */
export function parseJwtWellEnough(token: string) {
  try {
    return JSON.parse(Buffer.from(token.split('.')[1]!, 'base64').toString());
  } catch (e) {
    return null;
  }
}

/**
 * Provider component that will create two SplitIO clients instances .
 * One to target visitors and one that target users. These two SplitIO clients will also be
 * exposed on React context.
 */
export const SplitIOSetup = ({ children }: { children: ReactNode }) => {
  const { visitorToken } = useAppState();
  const searchParams = Object.fromEntries(useSearchParams() || []);

  const { visitorKey } = parseJwtWellEnough(visitorToken || '') ?? {};

  const splitIOVisitorConfig: SplitIO.IBrowserSettings = useMemo(
    () => ({
      core: {
        authorizationKey: SPLIT_BROWSER_API_KEY,
        key: visitorKey,
        trafficType: 'visitor',
      },
      startup: {
        readyTimeout: 5, // 5 seconds
      },
      storage: {
        type: 'LOCALSTORAGE',
      },
    }),
    [visitorKey],
  );

  const experimentOverrides = {
    manualOverrides: searchParams,
    experiments,
  };

  return (
    <SplitOverridesProvider
      isAuthorizedToInteractWithDevelopmentTools={isAuthorizedToInteractWithDevelopmentTools()}
      experimentOverrides={experimentOverrides}
    >
      <SplitFactory config={splitIOVisitorConfig}>
        <SplitIOVisitorProvider>
          <SplitIOUserProvider forceControlTreatment>
            {children}
          </SplitIOUserProvider>
        </SplitIOVisitorProvider>
      </SplitFactory>
    </SplitOverridesProvider>
  );
};
