import { locales as authLocales } from '@mentimeter/auth';
import { locales as votingBarLocales } from '@mentimeter/voting-bar';
import enUsAuth from './en-US/auth.json';
import deDeAuth from './de-DE/auth.json';
import esEsAuth from './es-ES/auth.json';
import ptBrAuth from './pt-BR/auth.json';

const locales = {
  'en-US': {
    auth: enUsAuth,
    authPackage: authLocales['en-US'].authPackage,
    votingBarPackage: votingBarLocales['en-US'].votingBarPackage,
  },
  'pt-BR': {
    auth: ptBrAuth,
    authPackage: authLocales['pt-BR'].authPackage,
    votingBarPackage: votingBarLocales['pt-BR'].votingBarPackage,
  },
  'es-ES': {
    auth: esEsAuth,
    authPackage: authLocales['es-ES'].authPackage,
    votingBarPackage: votingBarLocales['es-ES'].votingBarPackage,
  },
  'de-DE': {
    auth: deDeAuth,
    authPackage: authLocales['de-DE'].authPackage,
    votingBarPackage: votingBarLocales['de-DE'].votingBarPackage,
  },
};

export { locales };
