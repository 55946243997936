import { LocalStorage } from '@mentimeter/storage';
import { type ReactNode } from 'react';
import {
  getVisitorToken,
  VISITOR_LOCAL_STORAGE_KEY,
} from '@mentimeter/splitio';
import { AppStateContext } from '../../appState';

export function AppStateProvider({
  children,
  newlyGeneratedServerToken,
}: {
  children: ReactNode;
  newlyGeneratedServerToken: string;
}) {
  const localStorageVisitorToken = getVisitorToken();

  // we want to ensure that we don't re-render the page if we don't have a token in localStorage
  // so let's set it and use the one coming from the server as a fallback
  if (!localStorageVisitorToken) {
    LocalStorage.setItem({
      key: VISITOR_LOCAL_STORAGE_KEY,
      value: newlyGeneratedServerToken,
      type: 'functional',
    });
  }

  const visitorToken = localStorageVisitorToken || newlyGeneratedServerToken;

  return (
    <AppStateContext.Provider value={{ visitorToken }}>
      {children}
    </AppStateContext.Provider>
  );
}
