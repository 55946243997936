/* eslint-disable menti-react/filename-convention--jsx */
import { createContext, type ReactNode, useContext } from 'react';

export interface AppState {
  visitorToken: string | null;
}

export const AppStateContext = createContext<AppState | null>(null);
AppStateContext.displayName = 'AppStateContext';

export const useAppState = (): AppState => {
  const context = useContext(AppStateContext);
  if (context === null) {
    throw new Error('Cannot use appState outside of the provider');
  }
  return context;
};

export function AppStateConsumer({
  children,
}: {
  children: (arg0: AppState | null) => ReactNode;
}) {
  return (
    <AppStateContext.Consumer>
      {(context) => children(context)}
    </AppStateContext.Consumer>
  );
}
